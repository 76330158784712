<template>
</template>

<script>
export default {
  props: {
    cartItemsNumber: Number,
  },
  mounted() {
    const menuCartItems = document.getElementById('js-menu-cart-items');
    setInterval(() => {
      if (menuCartItems) {
        menuCartItems.innerText = this.cartItemsNumber;
      }
    }, 1000);
  },
}
</script>
