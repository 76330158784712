<template>
  <li class="license__item checkbox-custom" :class="license.selected ? 'selected' : ''">
    <input :id="'l_' + license.id" type="checkbox" class="checkbox-custom__input checkbox--inline" :checked="license.selected" @change="changeLicense($event)">
    <label :for="'l_' + license.id" class="buy__label checkbox-custom__label checkbox-custom--hidden">{{ license.name }}</label>
    <select-rate :from="'licenses'"  :font-id="fontId" :license-id="license.id" />
  </li>
</template>

<script>
import { mapActions } from 'vuex';
import SelectRate from "@/components/SelectRate";

export default {
  props: {
    fontId: Number,
    license: Object,
  },
  components: { SelectRate },
  methods: {
    ...mapActions([
       'toggleLicense',
     ]),
    changeLicense() {
      this.toggleLicense({ fontId: this.fontId, licenseId: this.license.id });
    },
  },
}
</script>
