<template>
  <div>
    <section class="section-buy" v-if="!loaded && specimen && page === 'specimen'">
      <h2 class="title-section">Buy {{ specimen.font.name }}</h2>
      <div class="buy-options">

        <div class="buy__licenses">
          <h3 class="title-subsection">Choose the license you need</h3>
          <p>There is a discount if you get more than one license type.</p>

          <div class="empty-licenses"></div>
          <div class="buy__captions">
            <p>Please read the <a href="">license agreement</a> for full compliance. </p>
            <p>For broadcasting and corporate licenses please <a href="mailto:info@novatypefoundry.com">contact us</a> to get a quote.</p>
          </div>
        </div>

        <div class="buy__styles">
          <h3 class="title-subsection">Get the full family or individual styles</h3>
          <p>Remember, buying upright and italic styles of the same family will give you inmediately an special price. </p>

          <div class="empty-licenses"></div>

        </div>

        <div class="loading-cart">
          <square-loader :visible="!loaded"></square-loader>
        </div>
      </div>
    </section>
    <div v-if="loaded && specimen && page === 'specimen'">
      <section class="section-buy">
        <h2 class="title-section">Buy {{ specimen.font.name }}</h2>
        <div class="buy-options">

          <div class="buy__licenses">
            <h3 class="title-subsection">Choose the license you need</h3>
            <p>There is a discount if you get more than one license type.</p>

            <licenses-list />

            <div class="buy__captions">
              <p>Please read the <a href="">license agreement</a> for full compliance. </p>
              <p>For broadcasting and corporate licenses please <a href="mailto:info@novatypefoundry.com">contact us</a> to get a quote.</p>
            </div>
          </div>

          <div class="buy__styles">
            <h3 class="title-subsection">Get the full family or individual styles</h3>
            <p>Remember, buying upright and italic styles of the same family will give you inmediately an special price. </p>

            <packages-list />

          </div>

        </div>
      </section>
      
      <shopping-cart :page="page" v-if="page === 'specimen' && cartItemsNumber > 0" />
    </div>

    <div class="cart-summary" v-if="loaded && page === 'cart'">
      <shopping-cart :page="page" />
    </div>

    <menu-total v-if="loaded && page !== 'cart' && page !== 'specimen'" :cartItemsNumber="cartItemsNumber"></menu-total>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SquareLoader from "@/components/SquareLoader";
import LicensesList from "@/components/LicensesList";
import PackagesList from "@/components/PackagesList";
import ShoppingCart from '@/components/ShoppingCart.vue'
import MenuTotal from "@/components/MenuTotal";

export default {
  components: { SquareLoader, LicensesList, PackagesList, ShoppingCart, MenuTotal },
  props: {
    fontSlug: String,
    page: {
      type: String,
      default: 'specimen',
    }
  },
  data: function() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      specimens: 'getSpecimens',
      specimen: 'getSpecimen',
      cartItemsNumber: 'getCartItemsNumber',
    })
  },
  created () {
    this.$store.dispatch("getData", this.fontSlug).then(() => {
      this.loaded = true
    });
  },
}
</script>