var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.cssClasses }, [
    _vm.cartItemsNumber > 0
      ? _c("header", { staticClass: "resume-header" }, [
          _c("h4", { staticClass: "page-title--3" }, [_vm._v("Cart Resume")]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-resume__count" }, [
            _c("p", { staticClass: "cart__items" }, [
              _c("span", { staticClass: "cart-count" }, [
                _vm._v(_vm._s(_vm.cartItemsNumber))
              ]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.cartItemsNumber === 1 ? "item" : "items") +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _vm.page === "specimen"
              ? _c("p", { staticClass: "cart__price" }, [
                  _c("span", { staticClass: "cart-count style__price" }, [
                    _vm._v(
                      _vm._s(
                        _vm.page === "specimen"
                          ? _vm.cartTotalWithoutCoupon
                              .subtract(_vm.taxAmountWithoutCoupon)
                              .toFormat()
                          : _vm.cartTotalWithCoupon.toFormat()
                      )
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.page === "specimen"
            ? _c("div", { staticClass: "cart__controls controls--reduced" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn--checkout cta js-ctaLoading",
                    attrs: { tabindex: "1" },
                    on: {
                      click: function($event) {
                        return _vm.checkout()
                      }
                    }
                  },
                  [_vm._v("Checkout")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.page === "specimen"
            ? _c(
                "button",
                {
                  staticClass: "btn--close cart__close",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.toggleCart($event)
                    }
                  }
                },
                [_vm._v("Close")]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.cartItemsNumber > 0
      ? _c("div", { staticClass: "cart-resume__list" }, [
          _c("div", { staticClass: "review-cart" }, [
            _vm.cartItemsNumber
              ? _c(
                  "a",
                  {
                    attrs: { href: "#cart-review" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.reviewCart($event)
                      }
                    }
                  },
                  [_vm._v("Review cart")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.cartItemsNumber
              ? _c("span", [_vm._v("Your cart is empty.")])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.cartItemsNumber
            ? _c(
                "ul",
                { staticClass: "cart-list", attrs: { id: "cart-review" } },
                _vm._l(_vm.groupedCartItems, function(license) {
                  return _c(
                    "li",
                    { staticClass: "cart__item" },
                    [
                      _c(
                        "p",
                        { staticClass: "cart__block item__license" },
                        [
                          _c("span", { staticClass: "license__name" }, [
                            _vm._v(_vm._s(license[0].licenseName))
                          ]),
                          _vm._v(" "),
                          _c("select-rate", {
                            attrs: {
                              from: "cart",
                              "font-id": license[0].fontId,
                              "license-id": license[0].licenseId
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(license, function(item) {
                        return _c(
                          "p",
                          { staticClass: "cart__block item__info" },
                          [
                            _c("span", { staticClass: "c-item__name" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.fontName) +
                                  " " +
                                  _vm._s(item.packageName) +
                                  "\n            "
                              ),
                              item.discount > 0
                                ? _c("span", { staticClass: "discount-msg" }, [
                                    _vm._v(
                                      "\n              -" +
                                        _vm._s(item.discount) +
                                        "% " +
                                        _vm._s(item.discountDescription) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "license__price" }, [
                              item.price.getAmount() !==
                              item.originalPrice.getAmount()
                                ? _c(
                                    "span",
                                    { staticClass: "price--discounted" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.originalPrice.toFormat())
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.price.toFormat()) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "cart-remove",
                                on: {
                                  click: function($event) {
                                    return _vm.removeItem($event, {
                                      type: item.type,
                                      fontId: item.fontId,
                                      licenseId: item.licenseId,
                                      id: item.packageId
                                    })
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "320",
                                      height: "512",
                                      viewBox: "0 0 320 512"
                                    }
                                  },
                                  [
                                    _c("title", [_vm._v("Remove item")]),
                                    _c("g", [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.page === "cart"
            ? _c("p", { staticClass: "cart__coupon cart__block" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.couponName,
                      expression: "couponName"
                    }
                  ],
                  staticClass: "coupon__input",
                  attrs: { type: "text", placeholder: "Have a coupon?" },
                  domProps: { value: _vm.couponName },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.checkCoupon()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.couponName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "coupon__btn",
                    attrs: { "aria-label": "Apply discount" },
                    on: {
                      click: function($event) {
                        return _vm.checkCoupon()
                      }
                    }
                  },
                  [_vm._v("Apply")]
                ),
                _vm._v(" "),
                _vm.coupon !== null
                  ? _c(
                      "button",
                      {
                        staticClass: "cart-remove remove--coupon is--visible",
                        on: {
                          click: function($event) {
                            return _vm.removeCoupon()
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "320",
                              height: "512",
                              viewBox: "0 0 320 512"
                            }
                          },
                          [
                            _c("title", [_vm._v("times")]),
                            _c("g", [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.coupon !== null && !_vm.coupon.valid
            ? _c(
                "p",
                {
                  staticClass: "cart__feedback coupon-box",
                  staticStyle: { "--status": "var(--c-error)" }
                },
                [_vm._v(_vm._s(_vm.coupon.message))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.coupon !== null && _vm.coupon.valid
            ? _c(
                "p",
                {
                  staticClass: "cart__feedback coupon-box",
                  staticStyle: { "--status": "var(--c-success)" }
                },
                [
                  _vm._v(
                    "Great! You got a -" +
                      _vm._s(_vm.coupon.discount) +
                      "% extra on non discounted items"
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.page === "cart" &&
            _vm.cartDiscountWithCoupon.getAmount() !== 0) ||
          (_vm.page === "specimen" &&
            _vm.cartDiscountWithoutCoupon.getAmount() !== 0)
            ? _c("div", { staticClass: "cart__block cart__discount" }, [
                _c("h5", [_vm._v("Discount")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "-" +
                      _vm._s(
                        _vm.page === "specimen"
                          ? _vm.cartDiscountWithoutCoupon.toFormat()
                          : _vm.cartDiscountWithCoupon.toFormat()
                      )
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.page === "cart"
            ? _c(
                "div",
                {
                  staticClass: "cart__block cart__taxes",
                  attrs: { id: "js-cart-taxes" }
                },
                [
                  _c("h5", [
                    _vm._v(
                      "Taxes (" + _vm._s(_vm.removeTaxes ? 0 : _vm.taxes) + "%)"
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.taxAmountFormatted))])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.cartItemsNumber
            ? _c("div", { staticClass: "cart__block cart__total" }, [
                _c("h5", [_vm._v("Total")]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    attrs: {
                      id: "js-cart-total",
                      "data-import":
                        _vm.page === "specimen"
                          ? _vm.cartTotalWithoutCoupon
                              .subtract(_vm.taxAmountWithoutCoupon)
                              .getAmount()
                          : _vm.cartTotalWithCoupon.getAmount()
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.page === "specimen"
                            ? _vm.cartTotalWithoutCoupon
                                .subtract(_vm.taxAmountWithoutCoupon)
                                .toFormat()
                            : _vm.cartTotalWithCoupon.toFormat()
                        ) +
                        "\n      "
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }