var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "licenses-list" },
    _vm._l(_vm.specimen.licenses, function(license) {
      return _c("license-item", {
        key: license.id,
        attrs: { "font-id": _vm.specimen.font.id, license: license }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }