var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "dropdown-custom dropdown--small dropdown--combo js-dropdown"
    },
    [
      _c(
        "span",
        {
          staticClass:
            "js-dropdownValue dropdown__value dropdown--right dropdown__arrow"
        },
        [_vm._v(_vm._s(_vm.selectedRateText))]
      ),
      _vm._v(" "),
      _c(
        "select",
        {
          staticClass: "buy__select",
          attrs: { id: _vm.selectId },
          on: {
            change: function($event) {
              return _vm.changeRate($event)
            }
          }
        },
        _vm._l(_vm.ratings, function(rate) {
          return _c(
            "option",
            { domProps: { value: rate.id, selected: rate.checked } },
            [_vm._v(_vm._s(rate.name))]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }