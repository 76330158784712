var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "style__item checkbox-custom is--style ",
      class:
        _vm.index % 2
          ? _vm.highlighted || _vm.checked
            ? "selected is--even"
            : "is--even"
          : _vm.highlighted || _vm.checked
          ? "selected is--odd"
          : "is--odd"
    },
    [
      _c(
        "label",
        {
          staticClass: "checkbox-custom__label buy__label",
          style: "font-family: " + _vm.item.encrypted,
          attrs: { for: "s_" + _vm.item.id, tabindex: "0" },
          on: {
            click: function($event) {
              return _vm.changeStyle($event)
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.changeStyle($event)
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.changeStyle($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.item.name))]
      ),
      _vm._v(" "),
      !_vm.hidePrice
        ? _c("span", { staticClass: "style__price" }, [
            _vm.price.getAmount() !== _vm.originalPrice.getAmount()
              ? _c("span", { staticClass: "price--discounted" }, [
                  _vm._v(_vm._s(_vm.originalPrice.toFormat()))
                ])
              : _vm._e(),
            _vm._v("\n    " + _vm._s(_vm.price.toFormat()) + "\n  ")
          ])
        : _c("span", { staticClass: "style__price in--bundle" }, [
            _vm._v("\n    In bundle\n  ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }