<template>
  <ul class="licenses-list">
    <license-item v-for="license in specimen.licenses" :key="license.id" :font-id="specimen.font.id" :license="license" />
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import LicenseItem from "@/components/LicenseItem";

export default {
  components: {
    LicenseItem,
  },
  computed: {
    ...mapGetters({
      specimen: 'getSpecimen',
    }),
  },
}
</script>
