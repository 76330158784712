var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loaded && _vm.specimen && _vm.page === "specimen"
        ? _c("section", { staticClass: "section-buy" }, [
            _c("h2", { staticClass: "title-section" }, [
              _vm._v("Buy " + _vm._s(_vm.specimen.font.name))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buy-options" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "loading-cart" },
                [_c("square-loader", { attrs: { visible: !_vm.loaded } })],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.specimen && _vm.page === "specimen"
        ? _c(
            "div",
            [
              _c("section", { staticClass: "section-buy" }, [
                _c("h2", { staticClass: "title-section" }, [
                  _vm._v("Buy " + _vm._s(_vm.specimen.font.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buy-options" }, [
                  _c(
                    "div",
                    { staticClass: "buy__licenses" },
                    [
                      _c("h3", { staticClass: "title-subsection" }, [
                        _vm._v("Choose the license you need")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "There is a discount if you get more than one license type."
                        )
                      ]),
                      _vm._v(" "),
                      _c("licenses-list"),
                      _vm._v(" "),
                      _vm._m(2)
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "buy__styles" },
                    [
                      _c("h3", { staticClass: "title-subsection" }, [
                        _vm._v("Get the full family or individual styles")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Remember, buying upright and italic styles of the same family will give you inmediately an special price. "
                        )
                      ]),
                      _vm._v(" "),
                      _c("packages-list")
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.page === "specimen" && _vm.cartItemsNumber > 0
                ? _c("shopping-cart", { attrs: { page: _vm.page } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.page === "cart"
        ? _c(
            "div",
            { staticClass: "cart-summary" },
            [_c("shopping-cart", { attrs: { page: _vm.page } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.page !== "cart" && _vm.page !== "specimen"
        ? _c("menu-total", { attrs: { cartItemsNumber: _vm.cartItemsNumber } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buy__licenses" }, [
      _c("h3", { staticClass: "title-subsection" }, [
        _vm._v("Choose the license you need")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("There is a discount if you get more than one license type.")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "empty-licenses" }),
      _vm._v(" "),
      _c("div", { staticClass: "buy__captions" }, [
        _c("p", [
          _vm._v("Please read the "),
          _c("a", { attrs: { href: "" } }, [_vm._v("license agreement")]),
          _vm._v(" for full compliance. ")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("For broadcasting and corporate licenses please "),
          _c("a", { attrs: { href: "mailto:info@novatypefoundry.com" } }, [
            _vm._v("contact us")
          ]),
          _vm._v(" to get a quote.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buy__styles" }, [
      _c("h3", { staticClass: "title-subsection" }, [
        _vm._v("Get the full family or individual styles")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Remember, buying upright and italic styles of the same family will give you inmediately an special price. "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "empty-licenses" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buy__captions" }, [
      _c("p", [
        _vm._v("Please read the "),
        _c("a", { attrs: { href: "" } }, [_vm._v("license agreement")]),
        _vm._v(" for full compliance. ")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("For broadcasting and corporate licenses please "),
        _c("a", { attrs: { href: "mailto:info@novatypefoundry.com" } }, [
          _vm._v("contact us")
        ]),
        _vm._v(" to get a quote.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }