<template>
  <span class="dropdown-custom dropdown--small dropdown--combo js-dropdown">
    <span class="js-dropdownValue dropdown__value dropdown--right dropdown__arrow">{{ selectedRateText }}</span>
    <select :id="selectId" class="buy__select" @change="changeRate($event)">
      <option v-for="rate in ratings" :value="rate.id" :selected="rate.checked">{{ rate.name }}</option>
    </select>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    from: String,
    fontId: Number,
    licenseId: Number,
  },
  data() {
    return {
      selectId: '',
    }
  },
  created() {
    this.selectId = `sr_${this.from}_${this.licenseId}`;
  },
  computed: {
    ...mapGetters({
      specimen: 'getSpecimen',
      cartItems: 'getCartItems',
    }),
    ratings: function () {
      if (undefined !== this.specimen) {
        if (undefined !== this.specimen.licenses) {
          const foundLicense = this.specimen.licenses.find(license => license.id === this.licenseId);
          return (undefined !== foundLicense) ? foundLicense.ratings : this.specimen.licenses[0].ratings;
        }
      }
    },
    selectedRateText: function() {
      if (undefined !== this.ratings) {
        const foundRate = this.ratings.find(rate => rate.checked);
        return (undefined !== foundRate) ? foundRate.name : this.ratings[0].name;
      }
    }
  },
  methods: {
    ...mapActions([
      'toggleLicense',
      'setLicenseRate'
    ]),
    changeRate(event) {
      this.setLicenseRate({
        fontId: this.fontId,
        licenseId: this.licenseId,
        rateId: parseInt(event.target.value),
      });

      // Cart Items
      let formCartItems = document.getElementById('cartItems');

      if (null !== formCartItems) {
        formCartItems.innerText = JSON.stringify(this.cartItems);
      }
    },
  },
}
</script>