<template>
  <li :class="item.isFullFont ? checked ? 'style__item checkbox-custom is--bundle is--full-package selected' : 'style__item checkbox-custom is--bundle is--full-package' : checked ? 'style__item checkbox-custom is--bundle selected' : 'style__item checkbox-custom is--bundle'" v-show="!hidden">
    <input type="checkbox" class="checkbox-custom__input checkbox--inline" :id="'b_' + item.id" :checked="checked" @change="changeBundle($event)" tabindex="-1">
    <label :for="'b_' + item.id" class="checkbox-custom__label buy__label" :class="item.isFullFont ? 'label--bundle' : ''" @keyup.enter="changeBundle($event)" @keydown.space.prevent="changeBundle($event)" tabindex="0">
      {{ font.name }}
      {{ item.name }}
      <span class="label-description" v-if="item.isFullFont">All styles of the
          {{ font.name }}
          family,
          {{ font.styles.length }}
          <template v-if="font.styles.length <= 1">font</template>
          <template v-else>fonts</template>
          in total</span>
    </label>

    <span class="style__price">
      <span class="price--discounted" v-if="price.getAmount() !== originalPrice.getAmount()">{{ originalPrice.toFormat() }}</span>
      {{ price.toFormat() }}
    </span>
  </li>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    font: Object,
    item: Object,
    price: Object,
    originalPrice: Object,
    checked: Boolean,
    hidden: Boolean,
  },
  methods: {
    ...mapActions([
      'toggleBundle',
    ]),
    changeBundle() {
      this.toggleBundle({ fontId: this.font.id, licenseId: null, bundleId: this.item.id });
    },
  },
}
</script>
