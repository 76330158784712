var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "license__item checkbox-custom",
      class: _vm.license.selected ? "selected" : ""
    },
    [
      _c("input", {
        staticClass: "checkbox-custom__input checkbox--inline",
        attrs: { id: "l_" + _vm.license.id, type: "checkbox" },
        domProps: { checked: _vm.license.selected },
        on: {
          change: function($event) {
            return _vm.changeLicense($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass:
            "buy__label checkbox-custom__label checkbox-custom--hidden",
          attrs: { for: "l_" + _vm.license.id }
        },
        [_vm._v(_vm._s(_vm.license.name))]
      ),
      _vm._v(" "),
      _c("select-rate", {
        attrs: {
          from: "licenses",
          "font-id": _vm.fontId,
          "license-id": _vm.license.id
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }