<template>
  <ul class="js-cartList styles-list">
      <bundle-item v-for="item in specimen.bundles" :key="'b_' + item.id" :font="specimen.font" :item="item" :checked="isChecked('b', item.id)" :price="getItemPrice(item.price)" :originalPrice="getItemOriginalPrice(item.price)" :hidden="isBundleHidden(item.id)" />
    <style-item v-for="(item, index) in specimen.styles" :key="'s_' + item.id" :font="specimen.font" :item="item" :checked="isChecked('s', item.id)" :price="getItemPrice(item.price)" :originalPrice="getItemOriginalPrice(item.price)" :index="index" :hide-price="isStylePriceHidden(item.id)" :highlighted="isStyleHighlighted(item.id)" />
  </ul>
</template>

<script>
import  { mapGetters } from "vuex";
import BundleItem from "@/components/BundleItem";
import StyleItem from "@/components/StyleItem";

export default {
  computed: {
    ...mapGetters({
      specimen: 'getSpecimen',
    }),
  },
  components: {
    BundleItem,
    StyleItem,
  },
  methods: {
    checkedLicensesByFont() {
      return this.$store.getters['getCheckedLicensesByFont'](this.specimen.font.id);
    },
    getItemOriginalPrice(baseItemPrice) {
      const checkedLicenses = this.checkedLicensesByFont();
      let itemPrice = this.$dinero({ amount: baseItemPrice * 100 });
      let price = checkedLicenses.length > 0 ? this.$dinero({}) : itemPrice;

      checkedLicenses.forEach(license => {
        let checkedRate = license.ratings.find(rate => rate.checked);

        if (undefined === checkedRate) {
          checkedRate = license.ratings[0];
        }

        price = price.add(itemPrice.multiply(checkedRate.price / 100));
      });

      return price;
    },
    getItemPrice(baseItemPrice) {
      let price = this.getItemOriginalPrice(baseItemPrice);

      // Direct discounts
      if (this.specimen.font.discount > 0) {
        price = price.subtract(price.percentage(this.specimen.font.discount));
      }

      return price;
    },
    isBundleHidden(id) {
      const currentLicense = this.specimen.licenses.find(l => l.selected);
      return currentLicense.hiddenBundles.includes(id);
    },
    isStylePriceHidden(id) {
      const currentLicense = this.specimen.licenses.find(l => l.selected);
      return currentLicense.hiddenStylePrices.includes(id);
    },
    isStyleHighlighted(id) {
      const currentLicense = this.specimen.licenses.find(l => l.selected);
      return currentLicense.highlightedStyles.includes(id);
    },
    isChecked(type, id) {
      let checked = false;

      if (type === 'b') {
        const currentLicense = this.specimen.licenses.find(l => l.selected);
        checked = currentLicense.selectedBundles.includes(id);
      }
      else if (type === 's') {
        const currentLicense = this.specimen.licenses.find(l => l.selected);
        checked = currentLicense.selectedStyles.includes(id);
      }

      return checked;
    },
  },
}
</script>
