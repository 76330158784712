<template>
  <section :class="cssClasses">
    <header class="resume-header"  v-if="cartItemsNumber > 0">
      <h4 class="page-title--3">Cart Resume</h4>
      <div class="cart-resume__count">
        <p class="cart__items">
          <span class="cart-count">{{ cartItemsNumber }}</span>
          {{ cartItemsNumber === 1 ? 'item' : 'items' }}
        </p>
        <p class="cart__price" v-if="page === 'specimen'">
          <span class="cart-count style__price">{{ (page === 'specimen') ? cartTotalWithoutCoupon.subtract(taxAmountWithoutCoupon).toFormat() : cartTotalWithCoupon.toFormat() }}</span>
        </p>
      </div>
      <div class="cart__controls controls--reduced" v-if="page === 'specimen'">
        <button tabindex="1" class="btn--checkout cta js-ctaLoading" @click="checkout()">Checkout</button>
      </div>
       <button class="btn--close cart__close" v-on:click.stop="toggleCart" v-if="page === 'specimen'">Close</button>
    </header >

    <div class="cart-resume__list"  v-if="cartItemsNumber > 0">
      <div class="review-cart">
        <a href="#cart-review" class="" v-on:click.stop="reviewCart" v-if="cartItemsNumber">Review cart</a>
        <span v-if="!cartItemsNumber">Your cart is empty.</span>
      </div>

      <ul class="cart-list" id="cart-review" v-if="cartItemsNumber">
        <li class="cart__item" v-for="license in groupedCartItems">
          <p class="cart__block item__license">
            <span class="license__name">{{ license[0].licenseName }}</span>
            <select-rate :from="'cart'" :font-id="license[0].fontId" :license-id="license[0].licenseId" />
          </p>
          <p class="cart__block item__info" v-for="item in license">
            <span class="c-item__name">
              {{ item.fontName }} {{ item.packageName }}
              <span class="discount-msg" v-if="item.discount > 0">
                -{{ item.discount }}% {{ item.discountDescription }}
              </span>
            </span>

            <span class="license__price">
              <span class="price--discounted" v-if="item.price.getAmount() !== item.originalPrice.getAmount()">
                {{ item.originalPrice.toFormat() }}</span>
                {{ item.price.toFormat() }}
            </span>

            <button class="cart-remove" @click="removeItem($event, { type: item.type, fontId: item.fontId, licenseId: item.licenseId, id: item.packageId })">
              <svg xmlns="http://www.w3.org/2000/svg" width="320" height="512" viewBox="0 0 320 512"><title>Remove item</title><g><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></g></svg>
            </button>
          </p>
        </li>
      </ul>

      <p class="cart__coupon cart__block" v-if="page === 'cart'">
        <input type="text" placeholder="Have a coupon?" class="coupon__input" v-model="couponName" @keyup.enter="checkCoupon()">
        <button class="coupon__btn" @click="checkCoupon()" aria-label="Apply discount">Apply</button>

        <button class="cart-remove remove--coupon is--visible" v-if="coupon !== null" @click="removeCoupon()">
          <svg xmlns="http://www.w3.org/2000/svg" width="320" height="512" viewBox="0 0 320 512"><title>times</title><g><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></g></svg>
        </button>
      </p>

      <p class="cart__feedback coupon-box" v-if="coupon !== null && !coupon.valid" style="--status: var(--c-error);">{{ coupon.message }}</p>
      <p class="cart__feedback coupon-box" v-if="coupon !== null && coupon.valid" style="--status: var(--c-success);">Great! You got a -{{ coupon.discount }}% extra on non discounted items</p>

      <div class="cart__block cart__discount" v-if="(page === 'cart' && cartDiscountWithCoupon.getAmount() !== 0) || (page === 'specimen' && cartDiscountWithoutCoupon.getAmount() !== 0)">
        <h5>Discount</h5>
        <p>-{{ (page === 'specimen') ? cartDiscountWithoutCoupon.toFormat() : cartDiscountWithCoupon.toFormat() }}</p>
      </div>

      <div class="cart__block cart__taxes" id="js-cart-taxes" v-if="page === 'cart'"> 
        <h5>Taxes ({{ removeTaxes ? 0 : taxes }}%)</h5>
        <p>{{ taxAmountFormatted }}</p>
      </div>

      <div class="cart__block cart__total" v-if="cartItemsNumber">
        <h5>Total</h5>
        <p id="js-cart-total" :data-import="(page === 'specimen') ? cartTotalWithoutCoupon.subtract(taxAmountWithoutCoupon).getAmount() : cartTotalWithCoupon.getAmount()">
          {{ (page === 'specimen') ? cartTotalWithoutCoupon.subtract(taxAmountWithoutCoupon).toFormat() : cartTotalWithCoupon.toFormat() }}
        </p>
      </div>

    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import groupBy from 'lodash/groupBy';
import SelectRate from "@/components/SelectRate";

export default {
  props: {
    page: String,
  },
  data: function() {
    return {
      visible: false,
      closed: false,
      couponName: null,
      removeTaxes: false,
      taxAmountFormatted: null,
    };
  },
  mounted() {
    setInterval(() => {
      if (localStorage.getItem('updT') === '1') {
        this.removeTaxes = false;

        let vies = (null === localStorage.getItem('vies')) ? false : localStorage.getItem('vies') === '1';
        let c = parseInt(localStorage.getItem('c'));
        let isEU = (null === localStorage.getItem('isEU')) ? false : localStorage.getItem('isEU') === 'true';

        this.removeTaxes = ((isEU && vies && c !== 178) || !isEU); // 178 = Portugal

        let removeTaxesInput = document.getElementById('removeTaxes');
        if (null !== removeTaxesInput) {
          removeTaxesInput.value = this.removeTaxes;
        }
        this.setTaxes();

        let btnPay = document.getElementById('js-btn-pay');
        let inputTotal = document.getElementById('cartTotal');
        if (this.coupon && this.coupon.valid) {
          this.taxAmountFormatted = this.taxAmountWithCoupon.toFormat();
          if (null !== btnPay) {
            btnPay.innerText = `Pay ${this.cartTotalWithCoupon.toFormat()}`;
          }
          if (null !== inputTotal) {
            inputTotal.value = this.cartTotalWithCoupon.getAmount();
          }
        }
        else {
          this.taxAmountFormatted = this.taxAmountWithoutCoupon.toFormat();
          if (null !== btnPay) {
            btnPay.innerText = `Pay ${this.cartTotalWithoutCoupon.toFormat()}`;
          }
          if (null !== inputTotal) {
            inputTotal.value = this.cartTotalWithoutCoupon.getAmount();
          }
        }

        let formCartItems = document.getElementById('cartItems');

        if (null !== formCartItems) {
          formCartItems.innerText = JSON.stringify(this.cartItems);
        }

        localStorage.setItem('updT', null);
      }
    }, 500);
  },
  components: { SelectRate },
  computed: {
    ...mapGetters({
      specimen: 'getSpecimen',
      cartItems: 'getCartItems',
      cartItemsNumber: 'getCartItemsNumber',
      cartDiscountWithCoupon: 'getCartDiscountWithCoupon',
      cartDiscountWithoutCoupon: 'getCartDiscountWithoutCoupon',
      cartTotalWithCoupon: 'getCartTotalWithCoupon',
      cartTotalWithoutCoupon: 'getCartTotalWithoutCoupon',
      coupon: 'getCoupon',
      taxes: 'getTaxes',
      taxAmountWithCoupon: 'getTaxAmountWithCoupon',
      taxAmountWithoutCoupon: 'getTaxAmountWithoutCoupon',
    }),
    groupedCartItems: function() {
      return groupBy(this.cartItems, 'licenseId');
    },
    cssClasses: function () {
      let classes = '';

      if (this.page === 'specimen') {
        classes = 'section-cart cart-resume added-item';

        if (this.closed) {
          classes += ' is--out';
        }
        else {
          classes += this.visible ? ' is--visible' : '';
        }

        classes += (this.cartItemsNumber === 0) ? ' is--hidden' : '';
      }
      else if (this.page === 'cart') {
        classes = 'cart-summary is--visible';
      }

      return classes;
    },
  },
  watch: {
    cartItemsNumber: function (newValue, oldValue) {
      if (newValue === 0) this.visible = false;
      if (oldValue < newValue) this.visible = this.closed = false;

      let emptyCart = document.getElementById('js-empty-cart');
      // let cartFormTitle = document.getElementById('js-cart-form-title');
      let cartForm = document.getElementById('js-cart-form');
      const signSuggestion = document.getElementById('js-login-suggestion');

      if (null !== emptyCart) {
        emptyCart.style.display = (newValue > 0) ? 'none' : 'block';
      }

      if (null !== cartForm) {
        // cartFormTitle.style.display = (newValue > 0) ? 'block' : 'none';
        cartForm.style.display = (newValue > 0) ? 'grid' : 'none';
      }

      if (null !== signSuggestion) {
        signSuggestion.style.display = (newValue > 0) ? 'block' : 'none';
      }

      // Updates the number of cart items in menu
      let menuCartItems = document.getElementById('js-menu-cart-items');

      if (null !== menuCartItems) {
        menuCartItems.innerText = newValue;
      }

      // Cart Items
      let formCartItems = document.getElementById('cartItems');

      if (null !== formCartItems) {
        formCartItems.innerText = JSON.stringify(this.cartItems);
      }
    },
    cartTotalWithCoupon: function (newValue, oldValue) {
      let btnPay = document.getElementById('js-btn-pay');
      const coupon = this.getCoupon();

      if (null !== btnPay) {
        if (coupon && this.getCoupon().valid) {
          btnPay.innerText = `Pay ${newValue.toFormat()}`;

          let inputDiscount = document.getElementById('cartDiscount');

          if (inputDiscount) {
            inputDiscount.value = this.cartDiscountWithCoupon.getAmount();
          }
        }
      }
    },
    cartTotalWithoutCoupon: function (newValue, oldValue) {
      let btnPay = document.getElementById('js-btn-pay');
      const coupon = this.getCoupon();

      if (null !== btnPay) {
        if (!coupon || !this.getCoupon().valid) {
          btnPay.innerHTML = `Pay ${newValue.toFormat()}`;

          let inputDiscount = document.getElementById('cartDiscount');

          if (inputDiscount) {
            inputDiscount.value = this.cartDiscountWithoutCoupon.getAmount();
          }
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'toggleBundle',
      'toggleStyle',
      'setCoupon',
      'setTaxes',
    ]),
    ...mapGetters([
      'getCoupon',
    ]),
    reviewCart() {
      this.closed = false;
      this.visible = true;
    },
    toggleCart() {
      if (!this.visible) this.closed = true;
      this.visible = !this.visible;
    },
    licenseRatings(licenseId) {
      const foundLicense = this.specimen.licenses.find(license => license.id === licenseId);
      return (undefined !== foundLicense) ? foundLicense.ratings : this.specimen.licenses[0].ratings;
    },
    removeItem(event, { type, fontId, licenseId, id }) {
      if (type === 'b') this.toggleBundle({ fontId, licenseId, bundleId: id });
      if (type === 's') this.toggleStyle({ fontId, licenseId, styleId: id, checked: false });

      if (this.cartItemsNumber === 0) {
        this.couponName = null;
        this.setCoupon(null);
      }

      localStorage.setItem('updT', '1');
    },
    checkCoupon() {
      // Cart Items
      let formCartItems = document.getElementById('cartItems');
      const discountBtn = document.querySelector('button[aria-label="Apply discount"]')

      discountBtn.classList.add('form__spinner')

      if (null !== formCartItems) {
        formCartItems.innerText = JSON.stringify(this.cartItems);
      }
      this.setCoupon(null);

      fetch(Routing.generate('api_check_coupon', { coupon: this.couponName }))
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.valid) {
              let cartFontsIds = [];

              for (let i = 0; i < this.cartItems.length; i++) {
                cartFontsIds.push(this.cartItems[i].fontId);
              }

              if (!cartFontsIds.some(id => data.fonts.includes(id))) {
                data.valid = false;
                data.message = 'This discount doesn\'t apply to the items in the cart';
              }
            }

            this.setCoupon(data);
            localStorage.setItem('updT', 1);

            if (null !== formCartItems) {
              formCartItems.innerText = JSON.stringify(this.cartItems);
            }
            discountBtn.classList.remove('form__spinner')
          })
          .catch((err) => {
            console.error(err);
          });
    },
    removeCoupon() {
      this.couponName = '';
      this.setCoupon(null);
      localStorage.setItem('updT', 1);
    },
    checkout() {
      document.location = Routing.generate('cart');
    },
    setTaxes() {
      let taxes = (this.removeTaxes) ? 0 : 23;
      this.$store.dispatch('setTaxes', taxes);
    },
    getSpecimenBundleStyles(fontId, bundleId) {
      return this.$store.state.specimens
          .filter(s => s.font.id === fontId)[0]
          .bundles
          .filter(b => b.id === bundleId)[0]
          .styles;
    },
  },
  beforeMount: function () {
    const cartItemsNumber = this.cartItems.length;

    // Shows or hide some elements
    let emptyCart = document.getElementById('js-empty-cart');
    let cartForm = document.getElementById('js-cart-form');
    let signSuggestion = document.getElementById('js-login-suggestion');

    if (null !== emptyCart) {
      emptyCart.style.display = (cartItemsNumber > 0) ? 'none' : 'block';
    }

    if (null !== cartForm) {
      cartForm.style.display = (cartItemsNumber > 0) ? 'grid' : 'none';
    }

    if (null !== signSuggestion) {
      signSuggestion.style.display = (cartItemsNumber > 0) ? 'block' : 'none';
    }

    // Updates the number of cart items in menu
    let menuCartItems = document.getElementById('js-menu-cart-items');

    if (null !== menuCartItems) {
      menuCartItems.innerText = cartItemsNumber;
    }

    // Cart Items
    let formCartItems = document.getElementById('cartItems');

    if (null !== formCartItems) {
      formCartItems.innerText = JSON.stringify(this.cartItems);
    }
  },
  created() {
    // let cartFormTitle = document.getElementById('js-cart-form-title');

    // if (null !== cartFormTitle) {
    //   cartFormTitle.style.display = (this.cartItemsNumber > 0) ? 'block' : 'none';
    // }
  },
}
</script>
