import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';
import App from '@/components/App.vue';
import Dinero from "dinero.js";
import _ from 'lodash';

Dinero.defaultAmount = 0;
Dinero.defaultCurrency = 'EUR';
Dinero.defaultPrecision = 2;
Dinero.globalLocale = 'de-DE'; // Format: 00.000,00 €
Dinero.globalFormat = '$0,0.00';

const domEl = '#app';

Vue.prototype.$dinero = Dinero;

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    fontSlug: null,
    specimens: [],
    coupon: null,
    taxes: 23,
  },
  mutations: { // Changes states
    SET_DATA(state, data) {
      state.fontSlug = data.font.slug;

      const specimenIndex = state.specimens.findIndex(specimen => specimen.font.id === data.font.id);

      if (-1 === specimenIndex) {
        // El especimen no estaba cargado así que le añado los checked y compruebo con el resto que licencias marcar
        data.licenses.forEach(license => {
          license.selected = license.checked = (license.slug === 'desktop');
          license.selectedBundles = [];
          license.hiddenBundles = []
          license.selectedStyles = [];
          license.hiddenStylePrices = [];
          license.highlightedStyles = [];

          license.ratings.forEach(rate => {
            let checkedRate = false;

            state.specimens.forEach(specimen => {
              const foundLicense = specimen.licenses.find(l => l.id === license.id);

              if (undefined !== foundLicense) {
                const foundRate = foundLicense.ratings.find(r => r.id === rate.id);

                checkedRate = (undefined !== foundRate) ? foundRate.checked : false;
              }
            });

            rate.checked = checkedRate;
          });
        });

        state.specimens.push(data);
      }
      else {
        // El especimen ya estaba cargado así que marco los checked que ya tenía y lo sustituyo en specimens
        const oldSpecimen = state.specimens[specimenIndex];

        data.licenses.forEach(license => {
          const oldLicense = oldSpecimen.licenses.find(oldLicense => oldLicense.id === license.id);
          license.selected = oldLicense ? oldLicense.selected : false;
          license.checked = oldLicense ? oldLicense.checked : false;
          license.selectedBundles = oldLicense ? oldLicense.selectedBundles : [];
          license.hiddenBundles = oldLicense ? oldLicense.hiddenBundles : [];
          license.selectedStyles = oldLicense ? oldLicense.selectedStyles : [];
          license.hiddenStylePrices = oldLicense ? oldLicense.hiddenStylePrices : [];
          license.highlightedStyles = oldLicense ? oldLicense.highlightedStyles : [];

          if (oldLicense) {
            license.ratings.forEach(rate => {
              const oldRating = oldLicense.ratings.find(oldRating => oldRating.id === rate.id);

              rate.checked = oldRating ? oldRating.checked : false;
            });
          }
        });

        if (data.licenses.every(license => !license.selected)) {
          data.licenses[0].selected = true;
        }

        state.specimens.splice(specimenIndex, 1, data);
      }
    },
    TOGGLE_LICENSE(state, { fontId, licenseId }) {
      const specimen = state.specimens.find(specimen => specimen.font.id === fontId);

      // Only one license could be selected at the same time
      specimen.licenses.forEach(license => license.selected = license.checked = false );

      if (undefined !== specimen) {
        const licenseIndex = specimen.licenses.findIndex(license => license.id === licenseId);

        if (-1 !== licenseIndex) {
          specimen.licenses[licenseIndex].checked = !specimen.licenses[licenseIndex].checked;
          specimen.licenses[licenseIndex].selected = true;
        }
      }

      // If there isn't any license checked I'll check 'Desktop'
      if (!specimen.licenses.some(license => license.checked)) {
        const licenseIndex = license.findIndex(l => l.slug === 'desktop');
        specimen.licenses[licenseIndex].selected = true;
      }
    },
    SET_LICENSE_RATE(state, { fontId, licenseId, rateId }) {
      state.specimens.forEach(specimen => {
        const licenseIndex = specimen.licenses.findIndex(license => license.id === licenseId);

        // Only one license could be selected at the same time
        specimen.licenses.forEach(license => license.selected = license.checked = false);

        if (-1 !== licenseIndex) {
          if (specimen.font.id === fontId) {
            specimen.licenses[licenseIndex].selected = specimen.licenses[licenseIndex].checked = true;
          }

          specimen.licenses[licenseIndex].ratings.forEach(rate => {
            rate.checked = rateId === rate.id;
          });
        }

        // If there isn't any license checked I'll check 'Desktop'
        if (!specimen.licenses.some(license => license.checked)) {
          const licenseIndex = specimen.licenses.findIndex(l => l.slug === 'desktop');
          specimen.licenses[licenseIndex].selected = true;
        }
      });

      localStorage.setItem('updT', '1');
    },
    TOGGLE_BUNDLE(state, { fontId, licenseId, bundleId }) {
      let currentLicense = null;

      if (null === licenseId) {
        currentLicense = state.specimens.find(s => s.font.id === fontId).licenses.find(l => l.selected);
      }
      else {
        currentLicense = state.specimens.find(s => s.font.id === fontId).licenses.find(l => l.id === licenseId);
      }

      currentLicense.selectedBundles = _.xor(currentLicense.selectedBundles, [bundleId]);

      // Si se ha marcado el bundle
      if (currentLicense.selectedBundles.includes(bundleId)) {
        // Si es FULL
        if (state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.id === bundleId).isFullFont) {
          // Desmarcar el resto de bundles
          const bundlesIdsToUncheck = state.specimens.find(s => s.font.id === fontId).bundles.filter(b => b.id !== bundleId).map(b => b.id);
          currentLicense.selectedBundles = currentLicense.selectedBundles.filter(id => !bundlesIdsToUncheck.includes(id));

          // Oculto el resto de bundles
          state.specimens.find(s => s.font.id === fontId).bundles.filter(b => b.id !== bundleId).forEach(b => {
            currentLicense.hiddenBundles.push(b.id);
          });

          // Quito el precio de todos los styles y los deselecciono porque es FULL
          state.specimens.find(s => s.font.id === fontId).styles.forEach(s => {
            currentLicense.hiddenStylePrices.push(s.id);
            currentLicense.highlightedStyles.push(s.id);
          });
        }
        // Si no es FULL
        else {
          // Quito el precio de los styles que incluye el bundle
          const stylesIdsToHidePrice = state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.id === bundleId).styles.map(s => s.id);
          state.specimens.find(s => s.font.id === fontId).styles.filter(s => stylesIdsToHidePrice.includes(s.id)).forEach(s => {
            currentLicense.hiddenStylePrices.push(s.id);
            currentLicense.highlightedStyles.push(s.id);
          });
        }
      }
      // Si se ha desmarcado el bundle
      else {
        // Si es FULL muestro el resto de bundles ocultos
        if (state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.id === bundleId).isFullFont) {
          state.specimens.find(s => s.font.id === fontId).bundles.filter(b => b.id !== bundleId).forEach(b => {
            currentLicense.hiddenBundles = currentLicense.hiddenBundles.filter(id => id !== b.id);
          });
        }

        // Muestro los precios de los styles que incluye el bundle y los desmarco
        const stylesIdsToShowPrice = state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.id === bundleId).styles.map(s => s.id);
        state.specimens.find(s => s.font.id === fontId).styles.filter(s => stylesIdsToShowPrice.includes(s.id)).forEach(s => {
          currentLicense.hiddenStylePrices = currentLicense.hiddenBundles.filter(id => id !== s.id);
          currentLicense.highlightedStyles = currentLicense.highlightedStyles.filter(id => id !== s.id);
          currentLicense.selectedStyles = currentLicense.selectedStyles.filter(id => id !== s.id);
        });
      }
    },
    TOGGLE_STYLE(state, { fontId, licenseId, styleId, checked }) {
      let currentLicense = null;

      if (null === licenseId) {
        currentLicense = state.specimens.find(s => s.font.id === fontId).licenses.find(l => l.selected);
      }
      else {
        currentLicense = state.specimens.find(s => s.font.id === fontId).licenses.find(l => l.id === licenseId);
      }

      // Si se ha marcado el style
      if (checked) {
        currentLicense.selectedStyles.push(styleId);
        state.specimens.find(s => s.font.id === fontId).bundles.forEach(bundle => {
          const stylesIdsInBundle = state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.id === bundle.id).styles.map(s => s.id);

          // Compruebo si el bundle contiene todos los styles marcados
          if (stylesIdsInBundle.every(id => currentLicense.selectedStyles.includes(id))) {
            // Si el bundle no está marcado, lo marco
            if (!currentLicense.selectedBundles.includes(bundle.id)) {
              currentLicense.selectedBundles.push(bundle.id);
            }

            // Oculto los precios de los styles que incluye el bundle
            state.specimens.find(s => s.font.id === fontId).styles.filter(s => stylesIdsInBundle.includes(s.id)).forEach(s => {
              currentLicense.hiddenStylePrices.push(s.id);
              currentLicense.highlightedStyles.push(s.id);
            });

            // Desmarco los styles que incluye el bundle
            stylesIdsInBundle.forEach(styleId => {
              currentLicense.selectedStyles = currentLicense.selectedStyles.filter(id => id !== styleId);
            });
          }

          // Comprobamos si están como selected todos los styles para marcar el FULL bundle
          const fullBundle = state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.isFullFont);

          // Tenemos un FULL
          if (null !== fullBundle && undefined !== fullBundle) {
            const numberOfSelectedStylesOfTheBundle = currentLicense.highlightedStyles.length + currentLicense.selectedStyles.length;

            // Si están seleccionados todos los styles que incluye el bundle los seleccionamos;
            if (numberOfSelectedStylesOfTheBundle === fullBundle.styles.length) {
              // Desmarcar el resto de bundles
              currentLicense.selectedBundles = [];

              // Marco el bundle
              currentLicense.selectedBundles.push(fullBundle.id);

              // Oculto el resto de bundles
              state.specimens.find(s => s.font.id === fontId).bundles.filter(b => b.id !== fullBundle.id).forEach(b => {
                currentLicense.hiddenBundles.push(b.id);
              });

              // Quito el precio de todos los styles y los deselecciono porque es FULL
              state.specimens.find(s => s.font.id === fontId).styles.forEach(s => {
                currentLicense.hiddenStylePrices.push(s.id);
                currentLicense.highlightedStyles.push(s.id);
              });

              // Deselecciono todos los styles
              currentLicense.selectedStyles = [];
            }
          }

        });
      }
      // Si se ha desmarcado el style
      else {
        currentLicense.selectedStyles = currentLicense.selectedStyles.filter(id => id !== styleId);

        // Recorro todos los bundles
        state.specimens.find(s => s.font.id === fontId).bundles.forEach(bundle => {
          // Si el bundle está marcado
          if (currentLicense.selectedBundles.includes(bundle.id)) {
            // Si es FULL
            if (bundle.isFullFont) {
              // Lo desmarco
              currentLicense.selectedBundles = currentLicense.selectedStyles.filter(id => id !== bundle.id);

              // Muestro el resto de bundles ocultos
              state.specimens.find(s => s.font.id === fontId).bundles.filter(b => !b.visible).forEach(b => {
                currentLicense.hiddenBundles = currentLicense.hiddenBundles.filter(id => id !== b.id);
              });

              // Selecciono los styles que incluye el bundle excepto el deseleccionado y muestro los precios en todos
              state.specimens.find(s => s.font.id === fontId).styles.forEach(style => {
                const stylesIdsInBundle = bundle.styles.map(s => s.id);

                currentLicense.hiddenStylePrices = currentLicense.hiddenStylePrices.filter(id => id !== style.id);
                currentLicense.highlightedStyles = currentLicense.highlightedStyles.filter(id => id !== style.id);

                currentLicense.selectedStyles = [];
                stylesIdsInBundle.forEach(id => {
                  if (styleId !== id) {
                    currentLicense.selectedStyles.push(id);
                  }
                });
              });

              // Compruebo si de los styles que quedan seleccionados algunos forman bundle
              state.specimens.find(s => s.font.id === fontId).bundles.forEach(bundle => {
                const stylesIdsInBundle = state.specimens.find(s => s.font.id === fontId).bundles.find(b => b.id === bundle.id).styles.map(s => s.id);

                // Compruebo si todos los styles del bundle están marcados
                if (stylesIdsInBundle.every(id => currentLicense.selectedStyles.includes(id))) {
                  // Marco el bundle
                  currentLicense.selectedBundles.push(bundle.id);

                  // Deselecciono los styles del bundle
                  stylesIdsInBundle.forEach(sId => {
                    if (currentLicense.selectedStyles.includes(sId)) {
                      currentLicense.selectedStyles = currentLicense.selectedStyles.filter(id => id !== sId);

                      // Quito el precio a todos los styles del bundle
                      currentLicense.hiddenStylePrices.push(sId);
                      currentLicense.highlightedStyles.push(sId);
                    }
                  });
                }
              });
            }
            // Si no es FULL
            else {
              const stylesIdsInBundle = bundle.styles.map(s => s.id);

              // Si el bundle incluye el estilo desmarcado
              if (stylesIdsInBundle.includes(styleId)) {
                // Desmarco el bundle
                currentLicense.selectedBundles = currentLicense.selectedBundles.filter(id => id !== bundle.id);

                // Muestro los precios de los style que incluye el bundle
                state.specimens.find(s => s.font.id === fontId).styles.forEach(style => {
                  if (stylesIdsInBundle.includes(style.id)) {
                    currentLicense.hiddenStylePrices = currentLicense.hiddenStylePrices.filter(id => id !== style.id);
                    currentLicense.highlightedStyles = currentLicense.highlightedStyles.filter(id => id !== style.id);

                    if (styleId !== style.id) {
                      currentLicense.selectedStyles.push(style.id);
                    }
                  }
                });

                // Deselecciono el style
                currentLicense.selectedStyles = currentLicense.selectedStyles.filter(id => id !== styleId);
              }
            }
          }
        });
      }
    },
    SET_COUPON(state, coupon) {
      state.coupon = coupon;

      let cartCoupon = document.getElementById('cartCoupon');
      if (state.coupon && cartCoupon && state.coupon.valid) {
        cartCoupon.value = state.coupon.coupon;
      }

      if (state.coupon === null) {
        cartCoupon.value = '';
      }
    },
    SET_TAXES(state, tax) {
      state.taxes = tax;
    },
  },
  actions: { // Actions call mutations that changes states
    getData(context, font) {
      fetch(Routing.generate('css_font_licenses_bundles_styles', { slug: font }))
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            context.commit('SET_DATA', data);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    toggleLicense(context, { fontId, licenseId }) {
      context.commit('TOGGLE_LICENSE', { fontId, licenseId });
    },
    setLicenseRate(context, { fontId, licenseId, rateId }) {
      context.commit('SET_LICENSE_RATE', { fontId, licenseId, rateId });
    },
    toggleBundle(context, { fontId, licenseId, bundleId }) {
      context.commit('TOGGLE_BUNDLE', { fontId, licenseId, bundleId });
    },
    toggleStyle(context, { fontId, licenseId, styleId, checked }) {
      context.commit('TOGGLE_STYLE', { fontId, licenseId, styleId, checked });
    },
    setCoupon: (context, coupon) => {
      context.commit('SET_COUPON', coupon);
    },
    setTaxes: (context, tax) => {
      context.commit('SET_TAXES', tax);
    },
  },
  getters: { // Gets states
    getSpecimens(state) {
      return state.specimens;
    },
    getSpecimen(state) {
      return state.specimens.find(specimen => specimen.font.slug === state.fontSlug);
    },
    getCheckedLicensesByFont: state => fontId => {
      const specimenIndex = state.specimens.findIndex(specimen => specimen.font.id === fontId);
      let licenses = [];

      if (undefined !== specimenIndex) {
        licenses = state.specimens[specimenIndex].licenses.filter(license => license.checked);
      }

      return licenses;
    },
    getCartItems: (state) => {
      let cartItems = [];
      let itemNumber = 1;

      state.specimens.forEach(specimen => {
        specimen.licenses.forEach(license => {
          let rate = license.ratings.find(rate => rate.checked);

          if (undefined === rate) rate = license.ratings[0];

          specimen.bundles
              .filter(bundle => license.selectedBundles.includes(bundle.id))
              .forEach(bundle => {
                const originalPrice = Dinero({ amount: bundle.price * 100 }).multiply(rate.price / 100);
                let price = { ...originalPrice };
                let discount = specimen.font.discount;
                let discountDescription = (specimen.font.discount > 0) ? 'Font discount' : '';
                let applyCoupon = specimen.font.discount === 0 && state.coupon && state.coupon.valid && state.coupon.fonts.includes(specimen.font.id);

                if (applyCoupon) {
                  discount = state.coupon.discount;
                  discountDescription = `Coupon ${state.coupon.coupon}`;
                }

                if (discount > 0) {
                  price = price.subtract(price.percentage(discount));
                }

                cartItems.push({
                  itemNumber,
                  fontId: specimen.font.id,
                  fontName: specimen.font.name,
                  fontDiscount: specimen.font.discount,
                  licenseId: license.id,
                  licenseName: license.name,
                  licenseSlug: license.slug,
                  rateId: rate.id,
                  rateName: rate.name,
                  ratePrice: rate.price,
                  type: 'b',
                  packageId: bundle.id,
                  packageName: bundle.name,
                  packagePrice: bundle.price,
                  packageFull: bundle.isFullFont,
                  originalPrice,
                  price,
                  discount,
                  discountDescription,
                  applyCoupon,
                });

                itemNumber++;
              });

          specimen.styles
              .filter(style => license.selectedStyles.includes(style.id))
              .forEach(style => {
                const originalPrice = Dinero({ amount: style.price * 100 }).multiply(rate.price / 100);
                let price = { ...originalPrice };
                let discount = specimen.font.discount;
                let discountDescription = (specimen.font.discount > 0) ? 'Font discount' : '';
                let applyCoupon = specimen.font.discount === 0 && state.coupon && state.coupon.valid && state.coupon.fonts.includes(specimen.font.id);

                if (applyCoupon) {
                  discount = state.coupon.discount;
                  discountDescription = `Coupon ${state.coupon.coupon}`;
                }

                if (discount > 0) {
                  price = price.subtract(price.percentage(discount));
                }

                cartItems.push({
                  itemNumber,
                  fontId: specimen.font.id,
                  fontName: specimen.font.name,
                  fontDiscount: specimen.font.discount,
                  licenseId: license.id,
                  licenseName: license.name,
                  licenseSlug: license.slug,
                  rateId: rate.id,
                  rateName: rate.name,
                  ratePrice: rate.price,
                  type: 's',
                  packageId: style.id,
                  packageName: style.name,
                  packagePrice: style.price,
                  packageFull: false,
                  originalPrice,
                  price,
                  discount,
                  discountDescription,
                  applyCoupon,
                });

                itemNumber++;
              });
        });
      });

      cartItems = _.sortBy(cartItems, ['licenseId', 'fontName', 'type']);

      /////////////////////////////////////////////////////////////////////////
      // Calculate discounts - CUSTOMIZED FOR NOVATYPE -
      /////////////////////////////////////////////////////////////////////////

      // If font doesn't have a discount. Check if there are 4 or more styles of any font
      // (license doesn't matter) added to the cart: -10%
      const fourOrMoreStyles = cartItems.filter(i => i.fontDiscount === 0 && i.type === 's');

      if (fourOrMoreStyles.length >= 4) {
        fourOrMoreStyles.forEach(i => {
          i.discount = 10;
          i.discountDescription = 'when buying 4 or more styles';
          i.price = i.originalPrice.subtract(i.originalPrice.percentage(i.discount));
        })
      }

      // If font doesn't have a discount. Check if there are 2 or more complete families
      // (license doesn't matter) added to the cart: -10%
      if (cartItems.filter(i => i.fontDiscount === 0 && i.type === 'b' && i.packageFull).length >= 2) {
        cartItems.filter(i => i.fontDiscount === 0 && i.type === 'b' && i.packageFull).forEach(item => {
          item.discount = 10;
          item.discountDescription = 'when buying 2 or more families';
          item.price = item.originalPrice.subtract(item.originalPrice.percentage(item.discount));
        });
      }

      // If font doesn't have a discount. Check if there are Desktop and Web license on the same family:
      // -50% of the cheapest
      Object.entries(_.groupBy(cartItems, 'fontId')).forEach(item => {
        const filteredItems = item[1].filter(i => (i.licenseSlug === 'desktop' || i.licenseSlug === 'webfont') && i.discount === 0);
        const groupedByLicense = _.groupBy(filteredItems, 'licenseSlug');

        if (groupedByLicense.hasOwnProperty('desktop') && groupedByLicense.hasOwnProperty('webfont')) {
          groupedByLicense.desktop.forEach(i => {
            const matchedItem = groupedByLicense.webfont.find(m => i.packageId === m.packageId);

            if (undefined !== matchedItem) {
              if (i.price.lessThanOrEqual(matchedItem.price)) {
                i.discount = 50;
                i.discountDescription = 'when buying desktop and web license';
                i.price = i.originalPrice.subtract(i.originalPrice.percentage(i.discount));
              } else {
                matchedItem.discount = 50;
                matchedItem.discountDescription = 'when buying desktop and web license';
                matchedItem.price = matchedItem.originalPrice.subtract(matchedItem.originalPrice.percentage(matchedItem.discount));
              }
            }
          });
        }
      });

      // If font doesn't have a discount. Check if there are standard item + italic item (by name) on same license
      // on the same family: -50% of the cheapest
      Object.entries(_.groupBy(cartItems, 'fontId')).forEach(item => {
        const groupedByLicense = Object.entries(_.groupBy(item[1], 'licenseSlug'));

        groupedByLicense.forEach(item => {
          const onlyStyles = item[1].filter(i => i.type === 's' && i.discount === 0);
          const onlyItalicStyles = onlyStyles.filter(i => i.packageName.toLowerCase().includes('italic'));
          const onlyNonItalicStyles = onlyStyles.filter(i => !i.packageName.toLowerCase().includes('italic'));

          onlyNonItalicStyles.forEach(nonItalicItem => {
            const matchedItalicItem = onlyItalicStyles.find(italicItem =>
              nonItalicItem.packageName.toLowerCase() + ' italic' === italicItem.packageName.toLowerCase()
            );

            if (undefined !== matchedItalicItem) {
              if (nonItalicItem.price.lessThanOrEqual(matchedItalicItem.price)) {
                nonItalicItem.discount = 50;
                nonItalicItem.discountDescription = 'when buying upright and italic';
                nonItalicItem.price = nonItalicItem.originalPrice.subtract(nonItalicItem.originalPrice.percentage(nonItalicItem.discount));
              }
              else {
                matchedItalicItem.discount = 50;
                matchedItalicItem.discountDescription = 'when buying upright and italic';
                matchedItalicItem.price = matchedItalicItem.originalPrice.subtract(matchedItalicItem.originalPrice.percentage(matchedItalicItem.discount));
              }
            }
          });
        });
      });

      return cartItems;
    },
    getCartItemsNumber: (state, getters) => {
      return getters.getCartItems.length;
    },
    getCartDiscountWithCoupon: (state, getters) => {
      const cartItems = getters.getCartItems;
      let totalDiscount = Dinero({});

      cartItems.filter(i => i.discount > 0).forEach(item => {
        totalDiscount = totalDiscount.add(item.originalPrice.subtract(item.price));
      });

      // Coupon
      if (state.coupon !== null && state.coupon.valid) {
        cartItems.filter(i => i.discount === 0).forEach(item => {
          totalDiscount = totalDiscount.add(item.originalPrice.percentage(state.coupon.discount));
        });
      }

      return totalDiscount;
    },
    getCartDiscountWithoutCoupon: (state, getters) => {
      const cartItems = getters.getCartItems;
      let total = Dinero({});

      cartItems.filter(i => i.discount > 0).forEach(item => {
        total = total.add(item.originalPrice.subtract(item.price));
      });

      return total;
    },
    getCartTotalWithCoupon: (state, getters) => {
      const cartItems = getters.getCartItems;
      let total = Dinero({});

      cartItems.forEach(item => total = total.add(item.price));
      total = total.add(total.percentage(state.taxes));

      return total;
    },
    getCartTotalWithoutCoupon: (state, getters) => {
      const cartItems = getters.getCartItems;
      let total = Dinero({});

      cartItems.forEach(item => total = total.add((item.applyCoupon) ? item.originalPrice : item.price));
      total = total.add(total.percentage(state.taxes));

      return total;
    },
    getCoupon: (state) => {
      return state.coupon;
    },
    getTaxes(state) {
      return state.taxes;
    },
    getTaxAmountWithCoupon(state, getters) {
      let cartSubTotal = getters.getCartTotalWithCoupon;
      return cartSubTotal.subtract(cartSubTotal.divide(1 + (getters.getTaxes / 100)));
    },
    getTaxAmountWithoutCoupon(state, getters) {
      let cartSubTotal = getters.getCartTotalWithoutCoupon;
      return cartSubTotal.subtract(cartSubTotal.divide(1 + (getters.getTaxes / 100)));
    },
  },
  strict: debug,
  plugins: [
    createMultiTabState({
      statesPaths: ['specimens'],
    }),
  ],
})

new Vue({
  el: domEl,
  store,
  render: h => h(App, {
    props: {
      fontSlug: document.querySelector(domEl).dataset.font,
      page: document.querySelector(domEl).dataset.page,
    }
  }),
});
