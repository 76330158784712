<template>
  <li class="style__item checkbox-custom is--style " :class="index % 2 ? (highlighted || checked) ? 'selected is--even' : 'is--even' : (highlighted || checked) ? 'selected is--odd' : 'is--odd'"  >
    <label :for="'s_' + item.id" class="checkbox-custom__label buy__label" :style="'font-family: ' + item.encrypted" @click="changeStyle($event)" @keyup.enter="changeStyle($event)" @keydown.space.prevent="changeStyle($event)" tabindex="0">{{ item.name }}</label>

    <span class="style__price" v-if="!hidePrice">
      <span class="price--discounted" v-if="price.getAmount() !== originalPrice.getAmount()">{{ originalPrice.toFormat() }}</span>
      {{ price.toFormat() }}
    </span>
    <span class="style__price in--bundle" v-else>
      In bundle
    </span>
  </li>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    font: Object,
    item: Object,
    price: Object,
    originalPrice: Object,
    index: Number,
    checked: Boolean,
    hidePrice: Boolean,
    highlighted: Boolean,
  },
  methods: {
    ...mapActions([
      'toggleStyle',
    ]),
    changeStyle(event) {
      this.toggleStyle({ fontId: this.font.id, licenseId: null, styleId: this.item.id, checked: !(this.highlighted || this.checked) });
    },
  },
}
</script>
