var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hidden,
          expression: "!hidden"
        }
      ],
      class: _vm.item.isFullFont
        ? _vm.checked
          ? "style__item checkbox-custom is--bundle is--full-package selected"
          : "style__item checkbox-custom is--bundle is--full-package"
        : _vm.checked
        ? "style__item checkbox-custom is--bundle selected"
        : "style__item checkbox-custom is--bundle"
    },
    [
      _c("input", {
        staticClass: "checkbox-custom__input checkbox--inline",
        attrs: { type: "checkbox", id: "b_" + _vm.item.id, tabindex: "-1" },
        domProps: { checked: _vm.checked },
        on: {
          change: function($event) {
            return _vm.changeBundle($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "checkbox-custom__label buy__label",
          class: _vm.item.isFullFont ? "label--bundle" : "",
          attrs: { for: "b_" + _vm.item.id, tabindex: "0" },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.changeBundle($event)
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.changeBundle($event)
            }
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.font.name) +
              "\n    " +
              _vm._s(_vm.item.name) +
              "\n    "
          ),
          _vm.item.isFullFont
            ? _c(
                "span",
                { staticClass: "label-description" },
                [
                  _vm._v(
                    "All styles of the\n        " +
                      _vm._s(_vm.font.name) +
                      "\n        family,\n        " +
                      _vm._s(_vm.font.styles.length) +
                      "\n        "
                  ),
                  _vm.font.styles.length <= 1
                    ? [_vm._v("font")]
                    : [_vm._v("fonts")],
                  _vm._v("\n        in total")
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "style__price" }, [
        _vm.price.getAmount() !== _vm.originalPrice.getAmount()
          ? _c("span", { staticClass: "price--discounted" }, [
              _vm._v(_vm._s(_vm.originalPrice.toFormat()))
            ])
          : _vm._e(),
        _vm._v("\n    " + _vm._s(_vm.price.toFormat()) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }