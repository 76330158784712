var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "js-cartList styles-list" },
    [
      _vm._l(_vm.specimen.bundles, function(item) {
        return _c("bundle-item", {
          key: "b_" + item.id,
          attrs: {
            font: _vm.specimen.font,
            item: item,
            checked: _vm.isChecked("b", item.id),
            price: _vm.getItemPrice(item.price),
            originalPrice: _vm.getItemOriginalPrice(item.price),
            hidden: _vm.isBundleHidden(item.id)
          }
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.specimen.styles, function(item, index) {
        return _c("style-item", {
          key: "s_" + item.id,
          attrs: {
            font: _vm.specimen.font,
            item: item,
            checked: _vm.isChecked("s", item.id),
            price: _vm.getItemPrice(item.price),
            originalPrice: _vm.getItemOriginalPrice(item.price),
            index: index,
            "hide-price": _vm.isStylePriceHidden(item.id),
            highlighted: _vm.isStyleHighlighted(item.id)
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }